<template>
  <div class="syj_BlacktwoPrize syj_page-width">
    <title class="commom-title"><span>二等奖</span></title>
    <div class="syjprice_money"><span>奖金</span>￥{{ twoprizemoney }}</div>
    <div v-if="twoprizemoney == 0" class="lunkong">很遗憾，该奖项轮空啦～</div>
    <ul v-else class="syj-content" :class="blackresultTwo.length < 4 ? 'onecenter' : 'one'">
      
      <li v-for="blackresult in blackresultTwo" :key="blackresult.key">
        
        <div class="left">
          <img :src="blackresult.avatar_url"/>
        </div>
        <div class="right">
          <div class="detail-name">
            <span class="name">{{ blackresult.name }}</span>
            <span class="num"> 中奖号码</span>
            <span>{{ blackresult.award_number }}</span>
          </div>
          <div class="tips">{{ blackresult.order_commitment }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "BlacktwoPrize",
  data() {
    return {};
  },
  props: {
    twoprizemoney: {
      type: [Number, String],
      default: ""
    },
    blackresultTwo: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.syjprice_money {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  margin-top: 30px;
  color: #fff;
  
  span {
    font-size: 24px;
    margin-top: 4px;
    color: #dd0e19;
  }
}

.tips {
  color: rgb(119, 119, 119);
  font-size: 15px;
  margin-top: 3px;
}
</style>
<style lang="less">
.syj_BlacktwoPrize {
  .lunkong {
    width: 100%;
    color: #fff;
    justify-content: center;
    margin-top: 20px;
  }
  
  .syj-content {
    &.onecenter {
      li {
        width: 30%;
        justify-content: center;
      }
      
      justify-content: center;
    }
    
    li {
      width: 33%;
      justify-content: flex-start;
    }
    
    justify-content: start;
    width: 85%;
    padding-left: 3%;
  }
}
</style>
