<template>
  <ul class="syj_blackmoney_one syj_page-width">
    <li>
      <div class="title">
        [
        <div>实际订单数</div>
        ]
      </div>
      <div class="number">{{ actualorder }}</div>
    </li>
    <li>
      <div class="title">
        [
        <div>奖池金额</div>
        ]
      </div>
      <div class="number">
        <div class="unit">￥</div>
        {{ BonuspoolAmount }}
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "blackresult",
  props: {
    actualorder: {
      type: [Number, String],
      default: ""
    },
    BonuspoolAmount: {
      type: [Number, String],
      default: ""//接收的数据名称为BonuspoolAmount，值类型为shuz
    },
  },
};
</script>
<style lang="less" scoped>
.syj_blackmoney_one {
  display: flex;
  justify-content: space-between;
  
  li {
    line-height: 1;
    width: 482px;
    height: 247px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    color: #ffd933;
    flex-direction: column;
    text-align: left;
    font-weight: 600;
    letter-spacing: 8px;
    background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/result-01.png") no-repeat top center;
    
    .title {
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #ffd933;
      letter-spacing: 1px;
      margin-top: 10px;
      
      div {
        margin: 0 18px;
        font-size: 36px;
        font-weight: 900;
      }
    }
    
    .number {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
}
</style>
