<template>
  <div class="syj_BlackfirstPrize syj_bg">
    <title class="commom-title"><span>一等奖</span></title>
    <div class="oneprize-content syj_page-width">
      <div class="syj_border-linear"></div>
      <div class="bgwhite">
        <div class="money">￥{{ fristprizemoney }}</div>
        <div v-if="fristprizemoney == 0" class="lunkong">
          很遗憾，该奖项轮空啦～
        </div>
        <ul class="syj-content" :class="blackresultlist.length == 1 ? 'onecenter' : 'one'">
          
          <li v-for="blackresult in blackresultlist" :key="blackresult.key">
            <div class="left">
              <img :src="blackresult.avatar_url"/>
            </div>
            <div class="right">
              <div class="detail-name">
                <span class="name" style="color: #0a0a0a;">{{ blackresult.name }}</span>
                <span class="num" style="color: #0a0a0a;"> 中奖号码</span>
                <span style="color: #0a0a0a;">{{ blackresult.award_number }}</span>
              </div>
              <div style="color: #777; font-size: 15px;margin-top: 3px;">{{ blackresult.order_commitment }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "firstPrize",
  // data() {
  //   return {
  //     fristprizemoney: "￥5,600",
  //     blackresultlist: [
  //       { resultname: "jenny", resultnumber: 124567 - 0 },
  //       { resultname: "孙艳杰", resultnumber: 124588 - 9 },
  //     ],
  //   };
  // },
  props: {
    fristprizemoney: {
      type: [Number, String],
      default: ""
    },
    blackresultlist: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
  },
  methods: {},
};
</script>
<style lang="less">

.syj_BlackfirstPrize {
  .bgwhite {
    background: #fff;
    padding: 30px 0;
  }
  
  .lunkong {
    color: #000;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  
  .oneprize-content {
    padding: 10px;
    margin-top: 15px;
    
    ul {
      width: 60%;
      justify-content: space-between;
      
      padding-left: 20px;
      li {
        justify-content: left;
      }
      
      &.onecenter {
        justify-content: center;
        
        li {
          justify-content: center;
        }
      }
    }
  }
  
  .money {
    background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/resultprize-bg2.png") no-repeat top center;
    height: 112px;
    font-size: 54px;
    color: #fff;
    font-weight: 600;
    padding-left: 8px;
    line-height: 114px;
  }
}

.syj-content {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  width: 82%;
  margin: 30px auto 0;
  
  li {
    width: 44%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: center;
    
    .left {
      border-radius: 50%;
      background-color: #fdf4ed;
      width: 52px;
      height: 52px;
      border: 2px solid #f04b38;
      overflow: hidden;
      display: flex;
      justify-content: center;
      
      img {
        max-width: fit-content;
        width: auto;
      }
    }
    
    .right {
      font-size: 16px;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      text-align: left;
      color: #fff;
      
      .num {
        padding: 0 10px;
      }
      
      .name {
        color: #fff;
        font-weight: 600;
      }
    }
  }
}
</style>
