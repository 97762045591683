<template>
  <div class="syj_BlackSpecialPrize">
    <title class="commom-title"><span>特别奖</span></title>
    <div class="syjprice_money"><span>奖金</span>￥{{ prize_especially }}</div>
    <ul class="syj-content">
      <li :class="newArr.length  > 8 ? 'moreleft' : ' '">
        <span
            v-for="item in newArr"
            :key="item.key"
            class="name"
        >{{ item.name }}<span v-if="item.num > 1">({{ item.num }}) </span>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "BlackSpecialPrize",
  data() {
    return {
      newArr: []
      
    };
  },
  props: {
    prize_especially: {
      type: [Number, String],
      default: "",
    },
    sprizelist: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    let that = this;
    let arr = that.sprizelist
    let newArr = [...new Set(arr)]
    let lastArr = []
    newArr.forEach(item => {
      // console.log(item)
      let num = 0
      arr.forEach(i => {
        if (item == i) {
          num++
        }
      })
      lastArr.push({
        name: item,
        num
      })
      this.newArr = lastArr;
      console.log("prize_especially_list", newArr)
    })
    console.log("lastArr", lastArr)
  }
  
  
};
</script>

<style lang="less" scoped>
.syjprice_money {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  margin-top: 30px;
  color: #fff;
  
  span {
    font-size: 24px;
    margin-top: 4px;
    color: #dd0e19;
  }
}
</style>

<style lang="less">
.syj_BlackSpecialPrize {
  li {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: #fff;
    
    &.moreleft {
      width: 56%;
      justify-content: left;
      
      & span {
        text-align: left;
      }
    }
    
    & span {
      width: calc(100% / 8);
      margin: 10px 0;
    }
  }
}
</style>
