<template>
  <div class="blackresultmian-home">
    <div class="bg-empty" v-if="ingstartnew == 0">
      <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/feihuodong.jpg"/>
    </div>
    <div v-else-if="ingstartnew == 1" class="syj_blackresultmian">
      <!-- logo 个人中心 -->
      <toplogoCustomer-info></toplogoCustomer-info>
      <div class="title"><span>{{ resulttile }}</span></div>
      <div class="syj-realtime-bottombg">
        <blackresult-info :actualorder="actualorder" :BonuspoolAmount="BonuspoolAmount"></blackresult-info>
        <!-- 一等奖组件 -->
        <firstPrize-info :blackresultlist="blackresultlist" :fristprizemoney="fristprizemoney"></firstPrize-info>
        
        <!-- 二等奖组件 -->
        <twotPrize-info :blackresultTwo="blackresultTwo" :twoprizemoney="twoprizemoney"></twotPrize-info>
        
        <!-- 三等奖组件 -->
        <threetPrize-info :prize_third="prize_third" :prizethreelist="prizethree"></threetPrize-info>
        
        <!-- 特别奖 -->
        <SpecialPrize-info :prize_especially="prizeespecially" :sprizelist="specialprizelist"></SpecialPrize-info>
        <!-- 获奖统计 -->
        <Awardstatistics-info :award_statistics="award_statisticlist" v-if="flag"></Awardstatistics-info>
      </div>
    </div>
  </div>

</template>
<script>
import confetti from 'canvas-confetti'

import Toplogocustomer from "@/components/toplogoCustomer";
import blackresult from "@/components/Winningresults/blackresultmoneytop";
import firstPrize from "@/components/Winningresults/BlackfirstPrize";
import twotPrize from "@/components/Winningresults/BlackTwoPrize";
import threetPrize from "@/components/Winningresults/BlackThreePrize";
import SpecialPrize from "@/components/Winningresults/BlackSpecialPrize";
import Awardstatistics from "@/components/Winningresults/BlackAwardPrize";
import {getActivity} from "../../units/activity.js";
import maiyuanRequest from "../../units/maiyaunRuquest.js";

export default {
  name: "blackresults",
  data() {
    return {
      flag: false,
      ingstart: 1,
      ingstartnew: null,
      resulttile: null,
      actualorder: "",
      BonuspoolAmount: "",
      fristprizemoney: " ",
      blackresultlist: [],
      
      twoprizemoney: " ",
      blackresultTwo: [],
      
      prize_third: "",
      prizethree: [],
      
      prizeespecially: ' ',
      specialprizelist: [],
      
      award_statisticlist: [],
      interval: null,
    };
  },
  components: {
    "blackresult-info": blackresult,
    "firstPrize-info": firstPrize,
    "threetPrize-info": threetPrize,
    "twotPrize-info": twotPrize,
    "SpecialPrize-info": SpecialPrize,
    "Awardstatistics-info": Awardstatistics,
    "toplogoCustomer-info": Toplogocustomer,
  },
  async mounted() {
    await this.getBlackResultMain()
  },
  
  beforeDestroy() {
    confetti.reset();
    clearInterval(this.interval)
  },
  
  methods: {
    async getBlackResultMain() {
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      
      try {
        const res = await maiyuanRequest({
          method: "get",
          url: "result_announcement",
          params: {
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId
          },
        })
        let response = res.data;
        if (response.status !== 200) {
          this.ingstartnew = 0
          return
        }
        this.fireworksDisplay()
        this.resulttile = response.data.title
        this.actualorder = response.data.betting_record_count;
        this.BonuspoolAmount = response.data.jackpot_amount;
        this.fristprizemoney = response.data.prize_one;
        this.blackresultlist = response.data.prize_one_list;
        this.twoprizemoney = response.data.prize_second;
        this.blackresultTwo = response.data.prize_second_list;
        
        this.prize_third = response.data.prize_third;
        this.prizethree = response.data.prize_third_list;
        this.prizeespecially = response.data.prize_especially;
        this.specialprizelist = response.data.prize_especially_list;
        
        this.ingstartnew = res.data.data.ing
        if (response.data.award_statistics) {
          this.data = response.data;
          this.flag = true
          
          this.award_statisticlist.name = this.data.award_statistics.name.map((item) => {
            return item
          })
          
          this.award_statisticlist.value = this.data.award_statistics.value.map((item) => {
            return item
          })
        }
      } catch (e) {
        console.log(e)
        
      }
    },
    
    /**
     * 中奖爆炸礼花特效
     */
    fireworksDisplay() {
      const time = 120  //秒
      const count = 150
      const duration = time * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
      
      const randomInRange = (min, max) => {
        return Math.random() * (max - min) + min;
      }
      
      this.interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();
        
        if (timeLeft <= 0) {
          return clearInterval(this.interval);
        }
        
        const particleCount = count * (timeLeft / duration);
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
      }, 250);
    }
  },
};
</script>
<style lang="less" scoped>
.bg-empty {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.syj_blackresultmian {
  background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homebg.jpg) repeat-y top center / cover #26003b;
  width: 100%;
  
  .homelogo {
    display: none;
  }
  
  .feihome {
    display: block;
  }
  
  .syjprice_money {
    span {
      color: #dd0e19;
      font-size: 24px;
    }
    
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ul {
    padding: 0;
  }
  
  .title {
    background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/resultbanner.png") no-repeat top center;
    width: 831px;
    height: 300px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
      font-size: 15px;
      padding-bottom: 7px;
      font-weight: bold;
      color: #f3e1cb;
      text-shadow: 0px 0px 8px rgba(11, 1, 29, 0.004);
      -moz-transform: matrix(2.67473779916776, -0.21279576751627, -0.78361019640945, 3.34369445979123, 0, 0);
      -webkit-transform: matrix(2.67473779916776, -0.21279576751627, -0.78361019640945, 3.34369445979123, 0, 0);
      -ms-transform: matrix(2.67473779916776, -0.21279576751627, -0.78361019640945, 3.34369445979123, 0, 0);
    }
  }
  
  .syj_enter-login-content {
    color: #fff;
    position: inherit;
  }
}
</style>
