<template>
  <div class="syj_BlackthreePrize syj_bg">
    <title class="commom-title"><span>三等奖</span></title>
    <div class="syjprice_money"><span>奖金</span>￥{{ prize_third }}</div>
    <ul class="syj-contentthree syj_page-width">
      <li class="first">
        <span
            v-for="blackresulttop in prizethreelabel"
            :key="blackresulttop.key"
        >
          {{ blackresulttop.namelabel }}</span
        >
      </li>
      <li v-if="prize_third == 0" class="lunkong">很遗憾，该奖项轮空啦～</li>
      <li v-else v-for="blackresult in prizethreelist" :key="blackresult.key">
        <span class="name"
        ><label class="namethree">
            <img :src="blackresult.avatar_url"/></label
        >{{ blackresult.name }}</span
        >
        <span>中奖号码 {{ blackresult.award_number }}</span>
        <div class="tips">{{ blackresult.order_commitment }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "BlackthreePrize",
  data() {
    return {
      prizethreelabel: [
        {namelabel: "获奖姓名"},
        {namelabel: "中奖号码"},
        {namelabel: "下单承诺"},
      ],
    };
  },
  
  props: {
    prize_third: {
      type: [Number, String],
      default: "",
    },
    prizethreelist: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.syjprice_money {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  margin-top: 30px;
  color: #fff;
  span {
    font-size: 24px;
    margin-top: 4px;
    color: #dd0e19;
  }
}
</style>
<style lang="less">
.syj_BlackthreePrize {
  ul {
    li.lunkong {
      width: calc(100% - 64px);
      justify-content: center;
    }
    
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin-top: 25px;
    
    li {
      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        
        .namethree {
          width: 26px;
          height: 26px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #fff;
          margin-right: 10px;
        }
      }
      
      font-weight: lighter;
      font-size: 16px;
      height: 50px;
      // border-bottom: 1px solid;
      color: #fff;
      // -o-border-image: linear-gradient(90deg, #363fb5, #363fb5, #363fb5) 1;
      // border-image: linear-gradient(90deg, #363fb5, #363fb5, #363fb5) 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 0 32px;
      
      &:nth-child(2n-1) {
        background: #363fb5;
      }
      
      &.first {
        font-size: 18px;
        // border-top: 1px solid;
        color: #fff;
        background-image: linear-gradient(90deg, #dd0e19, #dd0e19, #dd0e19);
        height: 46px;
      }
      
      & span {
        width: calc(100% / 3);
      }
    }
  }
}
</style>
